<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="14">
        <el-card class="card-1" style="padding: 10px 20px 13px">
          <div style="margin-bottom: 20px;font-size: 16px; font-weight: bold;">快捷入口</div>
          <div style="display: flex;justify-content: space-between;">
            <div v-for="(item, index) in entry" :key="index" style="text-align: center;">
              <div class="kjrk">
                <img style="height: 60%; top:50%" :src="item.icon" />
              </div>
              <div style="margin-top: 10px;color: #666666;font-weight: 500; font-size: 14px;">{{ item.name }}</div>
            </div>
          </div>
        </el-card>
        <div>
          <!-- 统计卡片 -->
          <div class="card-2" style="display: none; margin-bottom: 20px;">
            <div class="card ft-bold" v-for="(item, index) in cardList" :key="index">
              <!-- <i class="card-icon" :class="item.icon"></i> -->
              <span class="card-name ft-sz-12 c-white" :style="{ backgroundColor: item.color }">{{ item.name }}</span>
              <div class="ft-sz-30" style="margin-top: 0px">{{ item.data.num || 0 }}</div>
              <div class="card-trend ft-sz-14" style="color: #718096">
                较昨日
                <span :class="Number(item.data.ratio) > 0 ? 'up' : 'down'">
                  <i :class="Number(item.data.ratio) > 0 ? 'el-icon-top-right' : 'el-icon-bottom-right'"></i>
                  {{ Math.abs(Number(item.data.ratio) || 0) }}%
                </span>
              </div>
            </div>
          </div>
          <!-- 统计曲线图 -->
          <div class="echarts" style="margin-top: 20px;">
            <p>曲线图</p>
            <div id="myChart"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="10">
          <img style="width: 100%;height: 100px;display: block; margin-bottom: 20px;border-radius: 4px;" src="../../assets/manual/add002.png"/>
          <img style="width: 100%;height: 100px;display: block; margin-bottom: 20px;border-radius: 4px;" src="../../assets/manual/add001.png"/>
        <el-card class="card-4">
          <div style="margin-bottom: 20px;font-size: 16px; font-weight: bold;">最新公告</div>
          <div class="gonggao">
            <span class="time">2024-06-12 10:15:23</span>
            <div class="info">
              承接风控系统搭建！实现属于自己的私域！价格全网最低！实实在在权限给你自己！正常开户，正常充值，自己或者推广都合适！！
            </div>
          </div>
          <div class="gonggao">
            <span class="time">2024-05-12 11:40:20</span>
            <div class="info">
              多借条已经维护完毕，新资信、云条、速速签、立信契约、一纸含约均可查询详细信息。
            </div>
          </div>
          <div class="gonggao">
            <span class="time">2024-05-09 18:21:02</span>
            <div class="info">
              后台数据默认15天倒计时制删除，请各位老板们知晓！
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import service from "./api"
import echarts from "@/lib/echarts"
import dayjs from "dayjs"

export default {
  data () {
    return {
      entry: [{ name: '黑名单库', icon: require('../../assets/img/home/finance.png'), path: '' }, { name: '客户查询', icon: require('../../assets/img/home/blacklist.png'), path: '' },
      { name: '真假财务', icon: require('../../assets/img/home/element.png'), path: '' }, { name: '修改密码', icon: require('../../assets/img/home/password.png'), path: '' },
      { name: '账户管理', icon: require('../../assets/img/home/account.png'), path: '' }],
      cardData: {},
    }
  },
  created () {
  },
  mounted() {
    this.getData();
  },
  computed: {
        //余额
    balance() {
      return this.$store.state.balance || 0;
    },
    // 卡片数据
    cardList () {
      let {
        certification = {},
        pageView = {},
        register = {},
        through = {},
      } = this.cardData;
      return [
        {
          name: "今日查询",
          icon: "el-icon-view",
          color: "#f93b7a",
          data: pageView,
        },
        {
          name: "累计查询",
          icon: "el-icon-user",
          color: "#00bcd4",
          data: register,
        },
        {
          name: "今日消费",
          icon: "el-icon-postcard",
          color: "#fbb624",
          data: certification,
        },
        {
          name: "累计消费",
          icon: "el-icon-document-checked",
          color: "#f93b7a",
          data: through,
        },
      ];
    }
  },
  methods: {
    getData() {
      return service.getData().then((res) => {
        console.log(res)
        // 转换结构为 {'2020-01-01': {verify: 1, regist: 1}}
        let tmpStructRet = {};
        res.forEach((i) => (tmpStructRet[i.time] = i));
        // 生成 res 默认值
        let defaultData = {
          auditPassCount: 0,
          verifyCount: 0,
          pv: 0,
          regCount: 0,
          time: "",
        };
        // 补充 res 缺失的时间
        let fullRes = [];
        const today = dayjs(new Date());
        let day = dayjs(new Date()).subtract(10, "days");
        while (day.isBefore(today)) {
          day = day.add(1, "days");
          const dayStr = day.format("YYYY-MM-DD");
          if (tmpStructRet.hasOwnProperty(dayStr)) {
            fullRes.push(tmpStructRet[dayStr]);
          } else {
            defaultData.time = dayStr;
            fullRes.push(JSON.parse(JSON.stringify(defaultData)));
          }
        }
        // 生成折线图数据
        let charts = { date: [], verify: [], regist: [] };
        fullRes.forEach((item) => {
          charts.date.push(dayjs(item.time).format("MM-DD"));
          charts.verify.push(item.verifyCount);
          charts.regist.push(item.regCount);
        });
        this.drawLine(charts);
        // 计算上面四个值
        const todayDateStr = dayjs(new Date()).format("YYYY-MM-DD");
        const yesterdayDateStr = dayjs(new Date())
          .add(1, "days")
          .format("YYYY-MM-DD");
        const todayRes = tmpStructRet.hasOwnProperty(todayDateStr)
          ? tmpStructRet[todayDateStr]
          : defaultData;
        const yesterdayRes = tmpStructRet.hasOwnProperty(yesterdayDateStr)
          ? tmpStructRet[yesterdayDateStr]
          : defaultData;

        this.cardData = {
          pageView: {
            num: todayRes.pv,
            ratio: Math.min(
              Math.ceil((todayRes.pv / (yesterdayRes.pv || 1)) * 100),
              100
            ),
          },
          register: {
            num: todayRes.regCount,
            ratio: Math.min(
              Math.ceil(
                (todayRes.regCount / (yesterdayRes.regCount || 1)) * 100
              ),
              100
            ),
          },
          certification: {
            num: todayRes.verifyCount,
            ratio: Math.min(
              Math.ceil(
                (todayRes.verifyCount / (yesterdayRes.verifyCount || 1)) * 100
              ),
              100
            ),
          },
          through: {
            num: todayRes.auditPassCount,
            ratio: Math.min(
              Math.ceil(
                (todayRes.auditPassCount / (yesterdayRes.auditPassCount || 1)) *
                  100
              ),
              100
            ),
          },
        };
      });
    },
    // getContact(){
    //   return service.getContact().then(res => {
    //     this.wechat = res.sponsoredUserWechat;
    //     this.imageUrl = res.sponsoredUserWechatQrcode ? base +'api/common/attach/'+res.sponsoredUserWechatQrcode : ''
    //   })
    // },
    // 绘画曲线图
    drawLine(charts) {
      // 基于准备好的dom，初始化echarts实例

      let myChart = echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        color: ["#00bcd4", "#7043c1"],
        tooltip: { trigger: "item" },
        legend: {
          data: [""],
          icon: "circle",
          top: "bottom",
          color: "#000000",
          inactiveColor: "#555555",
        },
        xAxis: [{ type: "category", data: charts.date }],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            splitLine: { show: false },
            axisLine: { show: false },
          },
        ],
        series: [

          {
            name: "注册",
            type: "line",
            data: charts.regist,
            lineStyle: { width: 4 },
            itemStyle: { normal: { borderWidth: 4, borderColor: "#f93b7a" } },
            areaStyle: { color: "#7043c1" },
            smooth: true,
          },
        ],
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.gonggao{
  margin: 10px 0px 20px 0px;

}
.time{
  font-weight: 400;
  margin-bottom: 20px;
}
.info{
  font-size: 14px;
  color: #000;
}
.card-2{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}
.card-3{
  margin-bottom: 20px;
  padding: 10px 10px 10px 0px;
}
.card-4{
  margin-bottom: 20px;
  padding: 10px 10px 10px 0px;
}
.card {
  background: #ffffff;
  width: 23%;
  min-height: 36px;
  border-radius: 4px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06),
    0 1px 0 0 rgba(0, 0, 0, 0.02);
  padding: 20px;
  position: relative;

  &-icon {
    color: #e2e2f1;
    font-size: 24px;
    font-weight: 700;
  }

  &-name {
    position: absolute;
    right: 20px;
    top: 20px;
    padding: 4px 6px;
    border-radius: 3px;
  }

  &-trend {
    margin-top: 10px;

    .up {
      color: #1ecab8 !important;
    }

    .down {
      color: #f93b7a !important;
    }
  }
}
.echarts {
    padding: 20px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 20px;
    #myChart {
      width: 100%;
      height: 275px;
    }
  }
  .kjrk{
    width: 80px;height: 80px;background-color: #0f75ff;border-radius: 8px;text-align: center;padding: 22px 0 0 0;
  }
  .el-card__body, .el-main{
    padding: 26px 20px 30px 20px;
  }
  @media screen and (max-width: 480px) {
    .kjrk{
      width: 50px;height: 50px;background-color: #0f75ff;border-radius: 8px;text-align: center;padding: 15px 0 0 0;
    }
  }
  @media screen and (max-width: 1180px) {
    .kjrk{
      width: 70px;height: 70px;background-color: #0f75ff;border-radius: 8px;text-align: center;padding: 15px 0 0 0;
    }
    .el-col-14{
      width: 100%;
    }
    .el-col-10{
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .kjrk{
      width: 50px;height: 50px;background-color: #0f75ff;border-radius: 8px;text-align: center;padding: 15px 0 0 0;
    }
    .el-col-14{
      width: 100%;
    }
    .el-col-10{
      width: 100%;
    }
  }
</style>
