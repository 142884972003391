// 引入 ECharts 主模块
const echarts = require("echarts/lib/echarts");

require("echarts/lib/chart/line");
require("echarts/lib/chart/gauge");
// 引入提示框和标题组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");

export default echarts;
