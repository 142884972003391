import API from "@/api";

export default {
  getData: params => {
    return API.requestGet({
      url: "index/getDatas",
      params,
      auth: "true"
    });
  }
};
